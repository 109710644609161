.entity-paragraphs-item {
  margin: 2em 0;
}

.paragraphs-item-50-50 {
  margin-top: 40px;
  margin-bottom: 90px;
  div[class^="col"] {
    margin: 1em 0;
  }
  img {
    @extend .img-fluid;
  }
  ul {
    li {
      font-size: 17px;
    }
  }
  h4 {
    font-family: 'Rufina',serif !important;
    font-size: 30px;
    text-transform: uppercase;
    margin: 0 0 20px 0;
  }
}

@media (max-width: 768px) {
  .paragraphs-item-50-50 {
    margin-top: -10px;
    .content {
      ul {
        padding-left: 20px;
      }
    }
  }
}

.paragraphs-item-textarea {
    max-width: 75ch; /* Reduces width to max 75 Characters which is best practice */
    text-align: left;
    margin: 0 auto;
}

.a-buttons {
  a {
    @extend .btn;
    @extend .btn-primary;
  }
}

.content {
  h1 {
    @extend .c-text--serif;
    color: $brand-primary;
  }
  h2 {
    @extend .c-text--sans;
    @extend .c-font-weight--light;
    color: $brand-primary;
    &:after {
          content: "";
          position: relative;
          width: 150px;
          height: 1px;
          background: $brand-primary;
          display: block;
          margin: 0 auto;
          margin-top: 30px;
          margin-bottom: 25px;
    }
  }
  h3 {
      @extend .c-text--serif;
    }
    p {
      @extend .c-text--sans;
    }
}

@media (max-width: 768px) {
  .content {
    h2 {
      &::after {
        margin: 30px 0 25px 0;
      }
    }
  }
}

.entity-paragraphs-item {
  ul {
    padding: 0 15px;
    li {
      @extend .c-text--sans;
      list-style: none;
      color: $black;
      margin-left: 25px;
      font-weight: 300;
      &::before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 15px;
        background: url(/sites/all/themes/wirtimfeld/assets/angle-double-right.svg) no-repeat;
        margin-left: -25px;
        margin-top: 6px;
        position: absolute;
      }
    }
  }
}

.field--paragraphs_item-text_centered--field-text-mid {
  h3 {
    text-transform: uppercase;
  }
  h5 {
    text-transform: uppercase;
    font-size: 47px;
    letter-spacing: 3px;
    font-weight: 300;
    margin-top: 120px;
    margin-bottom: 80px;
  }
}

@media (max-width: 768px) {
  .field--paragraphs_item-text_centered--field-text-mid {
    h5 {
      font-size: 30px;
    }
  }
}

.paragraphs-item-text-7-5 {
  margin-top: 80px;
  margin-bottom: 80px;
}

p, b, strong {
  @extend .c-text--sans;
}

article {
  margin-bottom: 5rem;
  header {
    h2 {
      display: none;
    }
  }
  img {
    width: 100%;
    height: auto;
  }
    .field--node-blog--title-field {
      padding-left: 3rem;
      padding-right: 3rem;
      a {
        display: block;
        color: $gray-dark;
        @extend .c-text--serif;
        font-size: 23px;
        letter-spacing: 2px;
        margin-top: 1rem;
        margin-bottom: 1rem;
        transition: all ease-in-out 300ms;
        &:hover, :focus {
          color: $brand-primary;
          text-decoration: none;
        }
     }
    }
    .field--node-blog--field-datum {
      padding-left: 3rem;
      padding-right: 3rem;
      margin-bottom: 1.5rem;
      font-size: 14px;
      color: $black;
    }
    .field--node-blog--body {
      p {
        padding-left: 3rem;
      padding-right: 3rem;
      }
    }
  }

  .a-button-download {
    .field--field-paragraph-button {
      @extend .a-buttons;
      a {

        border-radius: 20px;
        padding-left: 20px;

        &:after {
          font-family: 'themify';
          content: "\e64b";
          margin-left: 13px;
          margin-right: 2px;

        }
      }
    }
  }

  .a-button-download.text-right{
    a {
      &:before{
        font-family: 'themify';
        content: "\e64b";
        margin-right: 13px;
        margin-left: 2px;
      }
      &:after {
          content: "";
          margin-left: 0px;
          margin-right: 0px;

        }
    }
  }

.vertical-center {
  .row {
    align-items: center;
  }
}

.switch-position {
  .row {
    .field-group-div:first-child {
      order: 2;
    }
  }
}

.youtube-field-player {
  width: 100% !important;
}

.border-pic-video {
  .youtube-field-player,
  img {
    border: 10px solid #fff;
  }
}

.highlighted-block {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 130px !important;
}

.gray-background {
  background: $gray-lighter;
}

.creme-background {
  background: $brand-creme;
}

.highlighted-block.u-full-width {
  .content {
      @extend .container;
    }
}

.field-text-mid {
  p {
    margin-bottom: 60px;
    strong {
      font-size: 23px;
      font-weight: 700;
      line-height: 35px;
      color: #333;
    }
  }
}

@media (max-width: 768px) {
  .field-text-mid {
    p {
      text-align: left;
      strong {
        font-size: 17px;
        line-height: 23px;
      }
    }
  }
}

.field-pg-full-width-image-image {
  margin: 50px 0 120px 0;
}

.field--paragraphs_item-text_7_5--field-text-right {
  h2 {
    &::after {
      margin: 30px 0 25px 0;
    }
  }
  h3 {
    margin-top: 20px;
  }
  p {
    margin-top: 20px;
    margin-bottom: 50px;
    font-size: 15px;
    letter-spacing: 1px;
  }
}

.field--paragraphs_item-text_7_5--field-paragraph-button {
  a {
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

.paragraphs-item-partner-links {
  a {
    display: block;
    color: black;
    transition: all 0.2s;
    font-weight: 300;
    &::before {
      content: "";
      background: url(/sites/all/themes/wirtimfeld/assets/angle-double-right.svg) no-repeat;
      width: 20px;
      height: 15px;
      display: inline-block;
      position: relative;
      top: 2px;
      margin-right: 10px;
    }
    &:hover {
      color: $brand-primary;
      text-decoration: none;
      transition: all 0.2s;
    }
  }
  .field-paragraph-headline {
    text-align: center;
    text-transform: uppercase;
    font-family: 'Rufina',serif;
    font-size: 30px;
    letter-spacing: 3px;
    margin-bottom: 50px;
  }
}

.field-paragraph-button {
  a {
    font-size: 15px !important;
    padding: 14px 25px !important;
    border-radius: 50px !important;
  }
}

.paragraphs-item-anfahrt {
  .field-company-name {
    color: #44a446;
    font-family: 'Rufina',serif;
    font-size: 29px;
    margin-bottom: 10px;
  }
  .field-strasse-plz {
    font-weight: bold;
    &::before {
      content: "";
      display: inline-block;
      background: url('/sites/all/themes/wirtimfeld/assets/icon_marker.svg') no-repeat 0 0;
      width: 20px;
      height: 20px;
      margin: 0 2px 0 0;
      position: relative;
      top: 3px;
    }
  }
  .field--ffnungszeiten-text {
    p {
      font-size: 16px;
    }
  }
  .field-telefon {
    font-weight: bold;
    margin: 20px 0;
    &::before {
      content: "\f095";
      font-family: FontAwesome;
      font-size: 20px;
      color: #44a446;
      margin: 0 8px 0 0;
      position: relative;
      top: 2px;
    }
  }
  .field-e-mail {
    font-weight: bold;
    &::before {
      content: "";
      display: inline-block;
      background: url('/sites/all/themes/wirtimfeld/assets/icon_mail.svg') no-repeat 0 0;
      width: 20px;
      height: 20px;
      margin: 0 6px 0 0;
      position: relative;
      top: 6px;
    }
  }
  .field-website {
    font-weight: bold;
    &::before {
      content: "";
      display: inline-block;
      background: url('/sites/all/themes/wirtimfeld/assets/icon_web.svg') no-repeat 0 0;
      width: 20px;
      height: 20px;
      margin: 0 6px 0 0;
      position: relative;
      top: 3px;
    }
  }
  .field--ffnungszeiten-headline {
    color: #44a446;
    font-family: 'Rufina',serif;
    font-size: 29px;
    margin-top: 40px;
    margin-bottom: 10px;
  }
}

.field-paragraph-button {
  a {
    background: #44a446;
    color: #fff;
    border-radius: 20px;
    border: 0;
    padding: 11px 10px 9px 10px;
    font-family: 'Oxygen',sans-serif;
    font-size: 14px !important;
    transition: all .2s;
    text-transform: uppercase;
    &:hover {
      background: #2e6e2f;
      transition: all 0.2s;
      text-decoration: none;
    }
  }
}

.field-paragraph-gallery {
  display: flex;
  flex-wrap: wrap;
  margin: 100px 0 150px 0;
  img {
    width: 24.5%;
    height: 100%;
    margin: 0.25%;
  }
}

@media (max-width: 600px) {
  .field-paragraph-gallery {
    img {
      width: 100%;
      height: auto;
    }
  }
}

@media (max-width: 992px) {
  .field--paragraphs_item-text_7_5--field-text-right {
    h3 {
      font-size: 24px;
    }
  }
}
