.c-tabs {
  padding: 0;
  margin: 60px 0;
  div {
    margin: 0;
    width: 100%;
    span {
      ul {
        width: 100%;
        margin-bottom: 0;
        &.tabs {
          display: inline-block;
          list-style-type: none;
          padding-left: 0;
          li {
            margin-top: -1px;
            a {
              display: block;
              color: #aaa;
              text-decoration: none;
              border: 1px solid #ccc;
              padding: 10px 15px;
              transition: all 0.2s;
              &.is-active {
                background: #eee;
                color: #000;
                border: 1px solid #ccc;
                border-bottom: 0;
              }
              &:hover {
                background: #eee;
                color: #000;
                transition: all 0.2s;
              }
            }
          }
        }
      }
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (min-width: 576px) {
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 798px) {
  .c-tabs {
    div {
      margin: 0 auto;
      width: auto;
      span {
        ul {
          &.tabs {
            li {
              float: left;
              a {
                border-top: 0;
                border-left: 0;
                border-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (min-width: 992px) {
}

// Large devices (desktops, less than 1200px)
@media (min-width: 1200px) {
}


