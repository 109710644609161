.block--blog-block-1, .block--news-events-block-1 {
  @extend .col-sm-6;
  margin-top: 20px;
  margin-bottom: 5rem;
  float: left;
  h2 {
    @extend .c-text--serif;
    @extend h3;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }
  .view--blog {
    border: 1px solid $gray-lighter;
    min-height: 550px;
  }
  article {
    margin-bottom: 2rem;
    .fields {
      .field--node-blog--title-field {
        padding-left: 1rem;
        padding-right: 1rem;
        @extend .c-text--sans;
        color: $brand-primary;
        font-size: 20px;
        margin-top: 20px;

          &:after {
            content: "";
            position: relative;
            width: 25%;
            height: 1px;
            background: $brand-primary;
            display: block;
            // margin: 0 auto;
            margin-top: 5px;
            margin-bottom: 25px;
          }
      }
      .field--type-text-with-summary {
        p {
          letter-spacing: 1px;
        }
      }
      .field--node-blog--field-datum {
        padding-left: 17px;
      }
      .field--node-blog--body {
        p {
          @extend .c-font-weight--bold;
          padding-left: 1rem;
          padding-right: 1rem;
          height: 180px;
          overflow: hidden;
        }
      }
    }
  }
  .blog-link {
    position: relative;
    padding-right: 20px;
    bottom: 1rem;
    text-align: right;
    a {
      font-size: 17px;
      letter-spacing: 1px;
    }
        a:first-child {
          text-transform: uppercase;
          &:after {
            font-family: 'themify';
            content: "\e649";
            color: $brand-primary;
            margin-left: 0.5rem;
            top: 1px;
            position: relative;
          }
          &:hover {
            text-decoration: none;
          }
        }
      }
}

.block--4 {
  position: absolute;
  margin-top: -350px;
  right: -30px;
  h2.title {
    display: none;
  }
}

@media (max-width: 1000px) {
  .block--4 {
    display: none;
  }
}

@media (max-width: 768px) {
  .block--blog-block-1, .block--news-events-block-1  {
    padding: 0;
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .block--blog-block-1, .block--news-events-block-1 {
    width: 50%;
  }
}
