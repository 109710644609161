/* Browser Resets
*********************************/
.flex-container a:active,
.flex-container a:focus,
.flexslider a:active,
.flexslider a:focus {
    outline: none;
}

.flex-control-nav,
.flex-direction-nav,
.slides {
    margin: 0;
    padding: 0;
    list-style: none;
}
/* FlexSlider Necessary Styles
*********************************/
.flexslider {
    /*@extend .u-full-width;*/
    margin: 0;
    padding: 0;
    margin-bottom: 3em !important;
}

.flexslider .slides > li {
    display: none;
    -webkit-backface-visibility: hidden;
}
/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides {
    position: relative;

    img {
        display: block;
        width: 100%;
        height: auto;
    }
}

.flex-pauseplay span {
    text-transform: capitalize;
}
/* Clearfix for the .slides element */
.slides:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: '\0020';
    line-height: 0;
}

html[xmlns] .slides {
    display: block;
}

* html .slides {
    height: 1%;
}
/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {
    display: block;
}

.flexslider {
    position: relative;
    overflow: hidden;

    .view__row {
        position: relative;
    }

    .slides > li {
        position: relative;
    }

    &:hover {
        .flex-next,
        .flex-prev {
            opacity: 1;
        }
    }
}

.flex-control-paging a {
    cursor: pointer;
}
/**
 * Controls
 */

.flex-direction-nav {

}

.flex-next,
.flex-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.2s;
    margin: 0 0.5em;

    .fa,
    span {
      font-size: 1.5em;
      &:before {
        @include media-breakpoint-up(sm) {
            font-size: 2em;
        }
      }
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35);
    }

    &,
    &:focus,
    &:hover,
    &:visited {
        color: #fff;
        text-decoration: none;
    }
}

.flex-next {
    right: 0;
}

.flex-disabled {
    display: none;
}

.flex-control-nav {
    width: 100%;
    position: absolute;
    height: auto;
    padding: 2px 0;
    text-align: center;

    a,
    li {
        display: inline-block;
    }

    a {
        margin: 0 0.2em;
        width: 0.8em;
        height: 0.8em;
        border-radius: 100%;
        text-decoration: none;
        text-indent: -999em;
        cursor: pointer;
        line-height: 71%;
    }

    .flex-active {

    }
}



.flex-caption {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    width: 100%;

/* this indents the caption into the container if u-full-width is used */
    .inner {
      @extend .container;
      position: relative;
      h1 {
          @extend .c-text--handwritten;
          margin-bottom: 0px;
          font-size: 4rem;
      }
      h2 {
          @extend .c-text--handwritten;
          margin-bottom: 0px;
          font-size: 4rem;
          text-transform: none;
          letter-spacing: 0;
      }
      h3 {
          @extend .c-text--sans;
          @extend .c-font-weight--light;
          text-transform: uppercase;
          font-size: 1.5rem;
      }
    }


    .hero-slide--bright-text,
    .slide-bright-font {
        &,
        h1,
        h2,
        h3,
        h4 {
            color: #fff;
        }

        .slide-bright-font {
            &,
            h1,
            h2,
            h3,
            h4 {
                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35);
            }
        }

        a {
            text-shadow: none;
        }

    }

    h1,
    h2,
    h4 {}

    a {
        transition: all, 0.2s;

        &:hover {
            text-decoration: none;
            background: #000;
            color: #fff;
        }
    }
}

.flex-caption {
  background: url('/sites/all/themes/wirtimfeld/assets/pattern_header_dotts.png') 0 0;
}

@media (min-width: 992px) {
  .flex-caption {
    .inner {
      top: -60px;
    }
  }
}

#CDSWIDEXC {
  margin: 0 !important;
}
