.flex-caption {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
  height: 100%;
  background: rgba(#000, 0.4);
  align-items: center;
  justify-content: center;
  display: flex;
  color: #fff;
  text-align: center;
  h1 {
    color: #fff;
    margin-bottom: 0;
    font-size: 70px !important;
  }
  h2 {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 300;
  }
}

.field-hero-slide-sub-title {
  font-family: $oswald-font;
  font-size: 30px;
  letter-spacing: 5px;
}

@media (max-width: 768px) {
  .flex-caption {
    h1 {
      font-size: 50px !important;
      text-align: center;
    }
  }
  .field-hero-slide-sub-title  {
    font-size: 25px;
    text-align: center;
  }
}
