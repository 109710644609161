#block--contact {
  padding: 0;
  margin-bottom: 100px;
  &::before {
    content: "Kontaktformular";
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: 'Rufina',serif;
    font-size: 29px;
    width: 100%;
    position: absolute;
    top: -90px;
  }
}

body.page-eform {
  #cmp--content {
    margin-top: -20px;
  }
  h1 {
    font-family: 'Rufina',serif;
    color: #44a446;
    font-size: 70px;
    text-align: center;
  }
  h2 {
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 300;
    letter-spacing: 6px;
    color: #44a446;
    text-align: center;
    &::after {
      content: "";
      position: relative;
      width: 150px;
      height: 1px;
      background: #44a446;
      display: block;
      margin: 0 auto;
      margin-top: 30px;
      margin-bottom: 25px;
    }
  }
  p {
    color: #333;
  }
}

@media (max-width: 768px) {
  body.page-eform {
    h1 {
      font-size: 45px;
      text-align: left;
    }
    h2 {
      text-align: left;
      font-size: 22px;
      &::after {
        margin: 30px 0 25px 0;
      }
    }
    p {
      text-align: left;
    }
  }
}

.message--status {
  display: none;
}

.submission-text {
  margin-bottom: 60px;
  p {
    text-align: center;
    img {
      margin-bottom: 85px;
    }
  }
}

.form__item {
  display: inline-block;
  margin-bottom: 30px;
  width: 100%;
}

.form-item {
  margin-bottom: 0;
}

.form__field {
  label {
    margin-bottom: 0;
  }
  input {
    padding: 8px;
    border: 2px solid #c1c1c1;
    transition: all 0.2s;
    width: 100%;
    &:focus {
      outline: none;
      border-color: #44a446;
      transition: all 0.2s;
    }
  }
  select {
    width: 100%;
    background: white;
    border: 2px solid #c1c1c1;
    border-radius: 0;
    color: $black;
    font-weight: 300;
    padding: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    transition: all 0.2s;
    &:hover {
      cursor: pointer;
    }
    &:focus {
      outline: none;
      border-color: #44a446;
      transition: all 0.2s;
    }
  }
}

.block--buchen__title {
  display: none;
}

#edit-field-anreise, #edit-field-abreise {
  .form__item {
    position: relative;
    &::after {
      content: "";
      background: url('/sites/all/themes/wirtimfeld/assets/icon_calender.png') no-repeat!important;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 36px;
      right: 10px;
    }
  }
}

#edit-field-ez, #edit-field-dz {
  .form__item {
    position: relative;
    &::after {
      content: "";
      background: url('/sites/all/themes/wirtimfeld/assets/select_arrow.png') no-repeat!important;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 42px;
      right: 10px;
    }
  }
}

.form__field--name-field-form-contact-message, .form__field--name-field-ihre-anmerkungen {
  textarea {
    width: 100%;
    height: 338px;
    padding: 8px;
    border: 2px solid #c1c1c1;
    resize: none;
    transition: all 0.2s;
    &:focus {
      outline: none;
      border-color: #44a446;
      transition: all 0.2s;
    }
  }
}

button[type=submit] {
  background: #44a446;
  color: #fff;
  border-radius: 20px;
  border: 0;
  padding: 11px 10px 9px 10px;
  font-family: 'Oxygen',sans-serif;
  font-size: 14px;
  transition: all .2s;
  text-transform: uppercase;
  &:hover {
    background: #2e6e2f;
    transition: all 0.2s;
  }
}

.block--schnell-buchung {
  margin-top: -30px;
  #edit-actions {
    display: flex;
    margin-bottom: 50px;
  }
}

@include media-breakpoint-up(xs) {
}

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-up(md) {
  .form__field {
    width: 49%;
  }

  #block--buchen {
    .form__field {
      float: left;
    }

    #edit-field-anreise {
      margin-right: 2%;
    }
    #edit-field-abreise {
      margin-right: 0;
    }
    #edit-field-ez {
      margin-right: 2%;
    }
    #edit-field-dz {
      margin-right: 0;
    }
    #edit-field-vor-nachname {
      margin-right: 2%;
    }
    #edit-field-nachname {
      margin-right: 0;
    }
    #edit-field-ihre-e-mail-adresse {
      margin-right: 2%;
    }
    #edit-field-ihre-telefonnummer {
      margin-right: 0;
    }
    #edit-field-ihre-anmerkungen {
      margin-right: 0;
      width: 100%;
    }
  }

  .block--schnell-buchung {
    margin-top: 0;
  }

  .form__field--name-field-form-contact-message {
    position: absolute;
    right: 0;
    top: 0;
    margin-left: 2%;
  }

  #edit-actions {
    width: 100%;
    button {
      width: 15%;
      float: right;
    }
  }

}

@include media-breakpoint-up(lg) {
}

@include media-breakpoint-up(xl) {
}

@media (max-width: 768px) {
  #block--contact {
    &::before {
      text-align: left;
    }
  }
}
