@import url('https://fonts.googleapis.com/css?family=Oxygen:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Rufina');
@import url('https://fonts.googleapis.com/css?family=Great+Vibes');



$modularscale: (
  base: 16px,
  ratio: 1.125,
  320px: (
    ratio: 1.125,
  ),
  900px: (
    ratio: 1.2,
  ),
  1200px: (
    ratio: 1.3,
  ),
);

p {
  font-size: 17px;
  font-weight: 300;
  line-height: 23px;
  color: $black;
  a {
    text-decoration: underline;
  }
  strong {
    font-weight: 700;
  }
}
body {
  font-family: 'Oxygen', sans-serif;
  color: #333;
  h1 {
    font-size: 70px;
  }
  h2 {
    text-transform: uppercase;
    font-size: 28px;
    letter-spacing: 6px;
  }
  h3 {
    font-size: 30px;
    letter-spacing: 3px;
    color: $black;
  }
  h4 {
    @include ms-respond(font-size,2);
  }
  h5 {
    @include ms-respond(font-size,1);
  }
}

@media (max-width: 768px) {
  body {
    h1 {
      font-size: 45px;
      text-align: left;
    }
    h2 {
      font-size: 22px;
      text-align: left;
    }
    h3 {
      text-align: left;
    }
  }
}

blockquote {
  @extend .blockquote;
  margin: 2em 3em 1em 1em;
  em {
    @extend .text-muted;
    font-size: smaller;
  }
}
