.paragraphs-item-gallery-slider {
  margin: 120px 0 !important;
  div {
    img {
      width: 100%;
      height: auto;
      border: 1px solid $white;
    }
  }
}

button {
  &.slick-arrow {
    background: url('/sites/all/themes/wirtimfeld/assets/icon_arrow_gallery.svg') no-repeat 0 0;
    width: 70px;
    height: 70px;
    border: 0;
    text-indent: -9999px;
    position: absolute;
    z-index: 100;
    transition: all 0.2s;
    &:hover {
      opacity: 0.5;
      transition: all 0.2s;
    }
  }
  &.slick-prev {
    transform: rotate(180deg);
    top: 10%;
    left: 10px;
  }
  &.slick-next {
    right: 10px;
    top: 10%;
  }
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

ul.slick-dots {
  padding: 0;
  margin-top: 20px;
  li {
    list-style: none;
    display: inline-block;
    margin: 10px 20px;
    &::before {
      display: none !important;
    }
    button {
      text-indent: -9999px;
      width: 20px;
      height: 20px;
      background: $white;
      border: 1px solid #333;
      border-radius: 50px;
    }
    &.slick-active {
      button {
        background: #333;
      }
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (min-width: 576px) {
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 768px) {
  .paragraphs-item-gallery-slider {
    div {
      img {
        width: 33%;
        height: auto;
      }
    }
  }

  .slick-list {
    margin-left: calc(-100vw / 2 + 500px / 2) !important;
    margin-right: calc(-100vw / 2 + 500px / 2) !important;
  }

  button {
    &.slick-prev {
      top: 40%;
    }
    &.slick-next {
      top: 40%;
    }
  }
}

@media (max-width: 768px) {
  .paragraphs-item-gallery-slider {
    margin: 0 !important;
  }
  .field--paragraphs_item-text_centered--field-text-mid h5 {
    margin-bottom: 40px !important;
  }
  .field-pg-full-width-image-image {
    margin-bottom: 50px !important;
  }
}

// Medium devices (tablets, less than 992px)
@media (min-width: 992px) {
}

// Large devices (desktops, less than 1200px)
@media (min-width: 1200px) {
}
