footer {
  margin-top: 150px;
}

#block--1 {
  h2.title {
    display: none;
  }
}

.adress-footer {
  background: url('/sites/all/themes/wirtimfeld/assets/bg_footer.png') no-repeat 0 0;
  background-size: cover;
  text-align: center;
  color: white;
  padding: 100px 0 0 0;
  margin-left: calc(-100vw / 2 + 500px / 2);
  margin-right: calc(-100vw / 2 + 500px / 2);
  h2 {
    font-family: 'Rufina',serif;
    font-size: 29px;
    color: #44a446;
    letter-spacing: 0;
    text-transform: none;
    margin-bottom: 15px;
  }
  p {
    color: $white;
    margin-bottom: 8px;
    &.adress {
      &::before {
        content: "";
        display: inline-block;
        background: url('/sites/all/themes/wirtimfeld/assets/icon_marker.svg') no-repeat 0 0;
        width: 20px;
        height: 20px;
        margin: 0 2px 0 8px;
        position: relative;
        top: 3px;
      }
    }
    &.open-days {
      text-transform: uppercase;
      letter-spacing: 2px;
      border-top: 1px solid #fff;
      margin-top: 20px;
      padding-top: 25px;
      &::before {
        content: "";
        display: inline-block;
        background: url('/sites/all/themes/wirtimfeld/assets/icon_time.svg') no-repeat 0 0;
        width: 20px;
        height: 20px;
        margin: 0 8px 0 8px;
        position: relative;
        top: 4px;
      }
    }
  }
  ul {
    li {
      display: inline-block;
      list-style: none;
      a {
        color: white;
      }
      &.phone {
        &::before {
          content: "\f095";
          font-family: FontAwesome;
          font-size: 20px;
          color: #44a446;
          margin: 0 8px 0 8px;
          position: relative;
          top: 2px;
        }
      }
      &.mail {
        &::before {
          content: "";
          display: inline-block;
          background: url('/sites/all/themes/wirtimfeld/assets/icon_mail.svg') no-repeat 0 0;
          width: 20px;
          height: 20px;
          margin: 0 6px 0 8px;
          position: relative;
          top: 6px;
        }
      }
      &.web {
        &::before {
          content: "";
          display: inline-block;
          background: url('/sites/all/themes/wirtimfeld/assets/icon_web.svg') no-repeat 0 0;
          width: 20px;
          height: 20px;
          margin: 0 6px 0 8px;
          position: relative;
          top: 3px;
        }
      }
      &.fb {
        &::before {
          content: "";
          display: inline-block;
          background: url('/sites/all/themes/wirtimfeld/assets/icon_fb.svg') no-repeat 0 0;
          width: 20px;
          height: 20px;
          margin: 0 -3px 0 8px;
          position: relative;
          top: 3px;
        }
      }
      &.youtube {
        &::before {
          content: "";
          display: inline-block;
          background: url('/sites/all/themes/wirtimfeld/assets/icon_youtube.svg') no-repeat 0 0;
          width: 20px;
          height: 20px;
          margin: 0 -3px 0 8px;
          position: relative;
          top: 3px;
        }
      }
    }
    &.footer-nav {
      text-align: right;
      margin-top: 80px;
      li {
        font-size: 15px;
        a {
          font-size: 15px;
          border-right: 1px solid #fff;
          padding-right: 8px;
          margin-right: 6px;
        }
      }
    }
  }
}

.logo-area {
  padding: 20px 0;
  img {
    margin: 0 10px;
  }
}

@media (max-width: 768px) {
  footer {
    .container {
      padding: 0;
      margin: 0;
      width: 100%;
    }
  }
  .adress-footer {
    padding: 100px 40px 0 40px;
    margin-left: 0;
    margin-right: 0;
    p {
      font-size: 15px;
      &.adress {
        text-align: left;
        padding-left: 35px;
        &::before {
          margin: 0 10px 0 0 !important;
          position: absolute;
          top: auto;
          left: 0;
        }
      }
      &.open-days {
        text-align: left;
        padding-left: 35px;
        &::before {
          margin: 0 10px 0 0 !important;
          position: absolute;
          top: auto;
          left: 0;
        }
      }
    }
    ul {
      padding-left: 0;
      li {
        display: block;
        text-align: left;
        a {
          font-size: 15px;
        }
        &::before {
          margin: 0 10px 0 0 !important;
        }
      }
      &.footer-nav {
        li {
          display: inline-block;
        }
      }
    }
  }
  .logo-area {
    display: none;
  }
}

body.page-eform p.adress {
  color: $white !important;
}

body.page-eform p.open-days {
  color: $white !important;
}
