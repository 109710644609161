.contact-map {
  margin: 100px 0 200px 0;
}

#block--2 {
  h2.title {
    display: none;
  }
}

.footer-map {
  margin: -100px 0 150px 0;
  .footer-map-info-top {
    text-align: center;
    background: #f5f5f5;
    position: relative;
    top: 16px;
    padding: 40px 0 30px 0;
    h3 {
      font-family: 'Rufina',serif;
      font-size: 27px;
      margin-bottom: 30px;
      text-align: center;
    }
    p {
      &.green {
        font-family: 'Rufina',serif;
        font-size: 24px;
        color: $brand-primary;
        margin-bottom: 8px;
      }
      &.data {
        margin-bottom: 0;
        &::before {
          content: "";
          display: inline-block;
          background: url('/sites/all/themes/wirtimfeld/assets/icon_marker_black.svg') no-repeat 0 0;
          width: 20px;
          height: 20px;
          margin: 0 2px 0 0;
          position: relative;
          top: 3px;
        }
      }
    }
  }
  .footer-map-info-bottom {
    background: #f5f5f5;
    display: inline-block;
    width: 100%;
    position: relative;
    top: -16px;
    padding: 35px 0 20px 0;
    p {
      &.tel {
        font-size: 19px;
        a {
          text-decoration: none;
          font-weight: 300;
          color: $black;
        }
        &::before {
          content: "\f095";
          font-family: FontAwesome;
          font-size: 20px;
          color: #44a446;
          margin: 0 8px 0 0;
          position: relative;
          top: 2px;
        }
      }
      &.mail {
        font-size: 19px;
        a {
          text-decoration: none;
          font-weight: 300;
          color: $black;
        }
        &::before {
          content: "";
          display: inline-block;
          background: url('/sites/all/themes/wirtimfeld/assets/icon_mail.svg') no-repeat 0 0;
          width: 20px;
          height: 20px;
          margin: 0 6px 0 0;
          position: relative;
          top: 6px;
        }
      }
      &.visit {
        font-family: 'Rufina',serif;
        font-size: 32px;
        line-height: 32px;
        color: $brand-primary;
      }
    }
  }
}

@media (min-width: 768px) {
  .footer-map-info-bottom {
    p {
      &.mail {
        text-align: right;
      }
    }
  }
}

@media (max-width: 768px) {
  .footer-map {
    margin: -100px 0 70px 0;
  }
}




.map-container {
	width: 100%;
}
.map-container iframe{
	width: 100%;
	display: block;
	pointer-events: none;
	position: relative; /* IE needs a position other than static */
}
.map-container iframe.clicked{
	pointer-events: auto;
}
