// Materia 4.0.0
// Bootswatch




// Table of Contents
//
// Colors
// Options
// Spacing
// Body
// Links
// Grid breakpoints
// Grid containers
// Grid columns
// Fonts
@import url('https://fonts.googleapis.com/css?family=Oswald:300');
$oswald-font: 'Oswald', sans-serif;


@mixin _assert-ascending($map, $map-name) {
  $prev-key: null;
  $prev-num: null;
  @each $key, $num in $map {
    @if $prev-num == null {
      // Do nothing
    } @else if not comparable($prev-num, $num) {
      @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    } @else if $prev-num >= $num {
      @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    }
    $prev-key: $key;
    $prev-num: $num;
  }
}

// Replace `$search` with `$replace` in `$string`
// @author Hugo Giraudel
// @param {String} $string - Initial string
// @param {String} $search - Substring to replace
// @param {String} $replace ('') - New value
// @return {String} - Updated string
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@mixin _assert-starts-at-zero($map) {
  $values: map-values($map);
  $first-value: nth($values, 1);
  @if $first-value != 0 {
    @warn "First breakpoint in `$grid-breakpoints` must start at 0, but starts at #{$first-value}.";
  }
}


// General variable structure
//
// Variable format should follow the `$component-modifier-state-property` order.


// Colors
//
// Grayscale and brand colors for use across Bootstrap.

// Start with assigning color names to specific hex values.
$white:  #fff !default;
$black:  #000 !default;
$red:    #d9534f !default;
$orange: #f0ad4e !default;
$yellow: #ffd500 !default;
$green:  #5cb85c !default;
$blue:   #0275d8 !default;
$teal:   #5bc0de !default;
$pink:   #ff5b77 !default;
$purple: #613d7c !default;

// Create grayscale
$gray-dark:                 #333333;
$gray:                      #2c2c2c;
$gray-light:                #bbb;
$gray-lighter:              #d4d4d4;
$gray-lightest:             #eee;

// Reassign color vars to semantic color scheme
$brand-primary:             #44a446;
$brand-green-light:         #e9f4e9;
$brand-creme:               #f4f1ec;
$brand-creme-dark:          #e5ddd3;

$brand-success:             #4CAF50;
$brand-info:                #9C27B0;
$brand-warning:             #ff9800;
$brand-danger:              #e51c23;
$brand-inverse:             $gray-dark !default;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded:            true !default;
$enable-shadows:            false !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-hover-media-query:  false !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer:   1rem !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: (
  0: (
    x: 0,
    y: 0
  ),
  1: (
    x: ($spacer-x * .25),
    y: ($spacer-y * .25)
  ),
  2: (
    x: ($spacer-x * .5),
    y: ($spacer-y * .5)
  ),
  3: (
    x: $spacer-x,
    y: $spacer-y
  ),
  4: (
    x: ($spacer-x * 1.5),
    y: ($spacer-y * 1.5)
  ),
  5: (
    x: ($spacer-x * 3),
    y: ($spacer-y * 3)
  )
) !default;
$border-width: 1px !default;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%
) !default;

// Body
//
// Settings for the `<body>` element.

$body-bg:       #fff !default;
$body-color:    $gray-dark;
$inverse-bg:    $gray-dark !default;
$inverse-color: $gray-lighter !default;


// Links
//
// Style anchor elements.

$link-color:            $brand-primary !default;
$link-decoration:       none !default;
$link-hover-color:      darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;
@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:               12 !default;
$grid-gutter-width-base:     30px !default;
$grid-gutter-widths: (
  xs: $grid-gutter-width-base,
  sm: $grid-gutter-width-base,
  md: $grid-gutter-width-base,
  lg: $grid-gutter-width-base,
  xl: $grid-gutter-width-base
) !default;
