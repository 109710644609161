.view--blog--page, .block--blog-block-2 {
  h2.title {
    display: none;
  }
  a {
    font-size: 30px !important;
    margin-top: 30px !important;
  }
  p {
    font-size: 15px;
    line-height: 35px;
  }
  .field-datum {
    margin: 15px 0 41px 0;
  }
  .field--node-blog--title-field {
    padding-left: 15%;
    padding-right: 15%;
    font-family: 'Rufina',serif;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 3px;
    color: #000;
    margin: 40px 0 30px 0;
  }
  .field--node-blog--field-datum {
    padding-left: 15%;
    padding-right: 15%;
  }
  .field--node-blog--body {
    padding-left: 15%;
    padding-right: 15%;
    p {
      padding: 0;
    }
  }
  ul.links {
    display: none;
  }
}

@media (max-width: 768px) {
  .view--blog--page {
    .field--node-blog--title-field {
      padding-left: 0%;
      padding-right: 0%;
    }
    .field--node-blog--field-datum {
      padding-left: 0%;
      padding-right: 0%;
    }
    .field--node-blog--body {
      padding-left: 0%;
      padding-right: 0%;
    }
  }
}

.field-add-view {
  h3.field__label {
    display: none;
  }
}

.view--news-events--block-2__content {
  .view__row {
    margin: 0 auto 80px auto;
  }
}

@media (max-width: 768px) {
  .field-text-right {
    text-align: left;
  }
  .text-right .field-paragraph-button {
    text-align: left;
    margin-bottom: 20px;
  }

  .view--news-events--block-2 {
    padding: 0 15px;
  }
}

@media (min-width: 768px) {
  .block--news-events-block, .block--news-events-block-2, .block--zimmerkategorie-block {
    .view__row {
      &:nth-child(even) {
        .fields .container {
          div {
            text-align: right;
            .field-datum-news {
              float: right;
            }
            .field-kategorie {
              float: right;
              &::after {
                content: "";
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

.block--news-events-block, .block--news-events-block-2, .block--zimmerkategorie-block {
  h2.title {
    display: none;
  }
  .view__row {
    margin-bottom: 80px;
    .group-left {
      margin-bottom: 20px;
    }
    &:nth-child(odd) {
      .fields .container {
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
      }
    }
    &:nth-child(even) {
      .fields .container {
        flex-direction: row-reverse;
        display: flex;
        flex-wrap: wrap;
        div {
        }
      }
    }
  }
  article {
    margin-bottom: 0;
  }
  .fields {
    display: flex;
    align-items: center;
    .container {
      display: flex;
      align-items: center;
    }
    div {
      .field-title-field {
        font-family: 'Rufina',serif;
        font-size: 30px;
        line-height: 30px;
        letter-spacing: 3px;
        color: #000;
        margin-bottom: 15px;
      }
      .field-datum-news {
        float: left;
        font-size: 14px;
        color: $black;
        letter-spacing: 0.5px;
      }
      .field-kategorie {
        float: left;
        font-size: 12px;
        color: #a9a7a7;
        letter-spacing: 0.5px;
      }
      .field-body {
        margin-top: 65px;
        p {
          margin-top: 20px;
          font-size: 15px;
          letter-spacing: 1px;
        }
      }
    }
  }
  ul.links {
    display: none;
  }
}

.field-button-vorschau {
  margin-top: 80px;
  a {
    background: #44a446;
    color: #fff;
    border-radius: 20px;
    padding: 11px 20px 11px 20px;
    font-size: 14px;
    transition: all .2s;
    &:hover {
      background: #2e6e2f;
      text-decoration: none;
      transition: all .2s;
    }
  }
}

.field-bild-startseite {
  img {
    width: 100%;
    height: auto;
  }
}

.view--blog--block-1 {
  .field--body {
    height: 180px;
    overflow: hidden;
  }
}

.block--news-events-block-1 {
  .view--news-events--block-1 {
    border: 1px solid #d4d4d4;
    min-height: 550px;
  }
  .field-datum-news.field--field-datum-news.field--type-datetime.field--node-news_eintrag--field-datum-news {
    padding-left: 17px;
    margin-bottom: 1.5rem;
    span {
      font-size: 14px;
      color: $black;
    }
  }
  .view__field--title {
    padding: 0 15px;
    font-size: 20px;
    margin-top: 20px;
    color: $brand-primary;
    &::after {
      content: "";
      position: relative;
      width: 25%;
      height: 1px;
      background: $brand-primary;
      display: block;
      // margin: 0 auto;
      margin-top: 5px;
      margin-bottom: 25px;
    }
  }
  .field-body {
    padding: 0 15px;
    height: 180px;
    overflow: hidden;
  }
  .view__row {
    margin: 0 15px;
    p {
      font-weight: 700;
      letter-spacing: 1px;

    }
  }
}

.block--news-events-block-2 {
  display: inline-block;
  width: 100%;
  h2.title {
    display: none;
  }
}

@media (min-width: 1200px) {
  .block--news-events-block-2 {
    .field-news-bild {
      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 30px;
        height: 392px;
        background: $brand-creme-dark;
        left: -15px;
        top: 10%;
      }
      &::after {
        content: "";
        display: inline-block;
        position: relative;
        width: 90%;
        height: 30px;
        background: $brand-creme-dark;
      }
    }
  }
}

.view--blog--block-1__footer {
  margin-top: 50px;
}

.view--news-events--block-1__footer {
  margin-top: 50px;
}

@media (min-width: 768px) {
  .block--news-events-block-2, {
    margin-bottom: 100px;
  }
}

#block-views-men-block, #block-views-men-block-1 {
  h2.title {
    display: none;
  }
  .col-lg-3 {
    padding: 0;
  }
  .field-title-field {
    position: absolute;
    top: 85px;
    right: 10px;
    color: $white;
    text-transform: uppercase;
    font-weight: 700;
    z-index: 99;
  }
  .field-datum-menu {
    position: absolute;
    top: 105px;
    right: 10px;
    color: $white;
    text-transform: uppercase;
    z-index: 99;
    span {
      font-weight: 300;
    }
  }
  .field-bild-menu {
    position: relative;
    &::after {
      content: "";
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      background: #000;
      opacity: .4;
      visibility: visible!important;
    }
  }
  .field-gerichte {
    background: $brand-green-light;
    padding: 25px 15px 16px 15px;
    p {
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 9px;
    }
  }
}

#block-views-men-block-1 {
  article {
    margin-bottom: 30px;
  }
  .field-gerichte {
    min-height: 150px;
  }
}

@media (max-width: 1200px) {
  .field-title-field {
    top: 65px !important;
  }
  .field-datum-menu {
    top: 85px !important;
  }
}

#block-views-men-block-1 {
  .field-title-field {
    right: 30px;
  }
  .field-datum-menu {
    right: 30px;
  }
}

@media (min-width: 992px) {
  #block-views-men-block {
    margin-top: -186px;
    img {
      height: 138px;
    }
  }
}

.block--packages-block-1 {
  display: inline-block;
  width: 100%;
  margin: 100px 0 0 0;
  h2.title {
    display: none;
  }
  h3.package-headline {
    text-align: center;
    &::after {
      content: "";
      position: relative;
      width: 25%;
      height: 1px;
      background: #44a446;
      display: block;
      margin: 20px auto 70px auto;
    }
  }
  .view__content {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
  }
  .view__row--odd {
    padding-left: 0;
  }
  .view__row--even {
    padding-right: 0;
  }
  .view__row {
  //  display: flex;
    header {
      display: none;
    }
    article {
      margin-bottom: 20px;
      border: 1px solid #d4d4d4;
      display: flex;
      flex-direction: column;
      min-height: 450px;
      justify-content: space-between;
      .field-title-field {
        font-size: 20px;
        margin-top: 20px;
        color: #44a446;
        padding: 0 15px;
        font-weight: 400;
        &::after {
          content: "";
          position: relative;
          width: 25%;
          height: 1px;
          background: #44a446;
          display: block;
          margin-top: 5px;
          margin-bottom: 25px;
        }
      }
      .field-body {
        padding: 0 15px;
        height: 180px;
        overflow: hidden;
      }
      ul.links {
        li {
          margin: 70px 20px 40px 0;
          text-align: right;
          &::before {
            display: none;
          }
          a {
            background: #44a446;
            color: #fff;
            border-radius: 20px;
            padding: 11px 20px 11px 20px;
            font-size: 14px;
            transition: all .2s;
            text-transform: uppercase;
            &:hover {
              background: #2e6e2f;
              text-decoration: none;
              transition: all .2s;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .view--zimmerkategorie--block {
    .group-left {
      padding: 0;
    }
    .group-right {
      padding: 0;
    }
  }
  .block--zimmerkategorie-block {
    .fields {
      div {
        .field-body {
          margin-top: 0;
        }
        .field-button-vorschau {
          margin-top: 40px;
        }
      }
    }
  }
  .view--packages--block-1 {
    .view__row {
      padding: 0;
    }
  }
  .view--zimmerkategorie--block__header {
    h3 {
      text-align: left !important;
    }
  }

  h3.package-headline {
    text-align: left !important;
    &::after {
      margin: 20px 0 70px 0 !important;
    }
  }

  .view--news-events--block__content {
    .container {
      padding: 0;
      .group-left {
        padding: 0;
      }
      .group-right {
        padding: 0;
      }
    }
  }
}

.page-eform-buchen, .page-eform-schnell-buchung, .page-eform-contact {
  .view--men---block {
    display: none;
  }
  .block--schnell-buchung {
    display: none;
  }
  #cmp--content {
    margin-top: 360px;
  }
}

.view--men---block {
  position: relative;
}

.block--schnell-buchung {
  padding: 37px 20px 20px 20px;
  background: $brand-creme-dark;
  position: relative;
  h2.title {
    display: none;
  }
}

@media (min-width: 768px) {
  .view--men---block {
    width: 25%;
    float: left;
  }
  .block--schnell-buchung {
    width: 73%;
    float: left;
    margin-left: 2%;
    margin-bottom: 200px;
    &.no_menu_today {
      width: 100%;
      margin-top: -186px;
      &::after {
        background-size: cover;
        height: 137px;
      }
    }
    label {
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 5px;
      color: #44a446;
      span {
        display: none;
      }
    }
    .form__item {
      margin-bottom: 10px;
    }
    .form__field {
      width: 18%;
      float: left;
      margin-right: 2%;
    }
    input {
      border: 1px solid #b4b4b4 !important;
    }
    select {
      border: 1px solid #b4b4b4 !important;
      font-size: 13px;
      padding: 11px 8px;
      color: $black;
    }
    .form-actions {
      width: 18% !important;
      margin-bottom: 0 !important;
      margin-top: 30px;
      button {
        width: 100% !important;
      }
    }
    #edit-field-abreise .form__item, #edit-field-anreise .form__item {
      input {
        font-size: 13px;
        padding: 11px 8px;
      }
      &::after {
        top: 41px;
      }
    }
    #edit-field-dz .form__item, #edit-field-ez .form__item {
      &::after {
        top: 47px;
      }
    }

    &::after {
      content: "";
      background: url('/sites/all/themes/wirtimfeld/assets/highlights.png') no-repeat;
      display: block;
      width: 100%;
      height: 132px;
      position: absolute;
      left: 0;
      background-size: contain;
      top: 138px;
    }
  }
}

.c-tabs {
  display: inline-block;
  width: 100%;
}

#cmp--content {
  display: inline-block;
  width: 100%;
}
