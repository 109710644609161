/*------------------------------------*\
  UTILITY
\*------------------------------------*/
/**
 * CONTENTS

 */

body {
  overflow-x: hidden;
}

.flex---row-space-between {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.u-full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-bottom: 0;
  img {
    @extend .img-fluid;
    width: 100%;
  }
}

.element-invisible {
  display: none;
}

.c-text--handwritten {
  font-family: 'Great Vibes', serif;
}

.c-text--serif {
  font-family: 'Rufina', serif;
}

.c-text--sans {
  font-family: 'Oxygen', sans-serif;
}

.c-font-weight--light {
  font-weight: 300;
}

.c-font-weight--regular {
  font-weight: 400;
}

.c-font-weight--bold {
  font-weight: 700;
}

li.translation_de {
  display: none;
}

li.translation_en {
  display: none;
}
