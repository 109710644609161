button.navbar-toggler {
  margin-top: 10px;
  right: 0;
}

span.icon-bar {
  width: 30px;
  height: 3px;
  background: #000;
  display: block;
  margin: 5px 0;
}

.navbar {
  padding-left: 0;
  padding-right: 0;
}


////// Top Menu

.top-nav {
  background: $brand-creme;
  height: 55px;
  .navbar {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 6px 0;
    .top-menu-left .menu__list li,
    .top-menu-right .menu__list li {
      display: inline-block;
      font-size: 1rem;
      a {
        font-size: 14px;
        padding: 0 5px;
      }
    }
    .top-menu-left {
      .menu__list {
        padding-left: 0;
        li.last {
            a {
              @extend .c-font-weight--bold;
              color: $brand-primary;
            }
        }

        /*li.first {
          a {
            top: 6px;
            position: relative;
            display: block;
            width: 33px;
            height: 23px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            text-indent: -9999px;
            border: none;
            background: url(/sites/all/themes/wirtimfeld/assets/language_DE.png) no-repeat !important;
            transition: all 0.2s;
            &:hover {
              opacity: 0.5;
              transition: all 0.2s;
            }
          }
        }
        li:nth-child(2n) {
          a {
            top: 6px;
            position: relative;
            display: block;
            width: 33px;
            height: 23px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            text-indent: -9999px;
            border: none;
            background: url(/sites/all/themes/wirtimfeld/assets/language_EN.png) no-repeat !important;
            transition: all 0.2s;
            &:hover {
              opacity: 0.5;
              transition: all 0.2s;
            }
          }
        }*/
      }
    }
    .top-menu-right {
      .menu__list {

        li {
          &:nth-child(even) {
            margin-right: 0;
          }
        }
        li.last {
          a {
           background: $brand-primary;
           color: #fff;
           border-radius: 20px;
           padding: 11px 10px 9px 10px;
           font-size: 14px;
           transition: all 0.2s;
           &:hover {
             background: #2e6e2f;
             transition: all 0.2s;
           }
          }
        }
      }
    }
  }
}

#block--menu-top-menu-left {
  ul {
    li {
      a {
        padding-left: 0;
      }
      &.last {
        display: none;
      }
    }
  }
}

@media (min-width: 992px) {
  .top-nav {
    .navbar {
      .top-menu-left .menu__list li,
      .top-menu-right .menu__list li {
        a {
          font-size: 17px;
          padding: .5em 1em;
        }
        &.last {
          a {
            padding: 11px 30px 9px 30px;
          }
        }
        .top-menu-right {
          .menu__list {
            li {
              &:nth-child(even) {
                margin-right: 20px;
              }
              a {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
  #block--menu-top-menu-left {
    ul {
      li {
        &.last {
          display: inline-block;
        }
      }
    }
  }
  #block--main-menu {
    ul {
      li {
      //  margin: 0 0 5px 0 !important;
        a {
          padding: 0 0.8rem !important;
        }
      }
      .dropdown-menu {
        li {
          &.first {
            margin-top: 10px !important;
          }
        }
      }
    }
  }
}

.block--menu-top-menu-left {
  ul {
    li {
      &.last {
        margin-left: 5px;
        a {
          letter-spacing: 1px;
          &::before {
            content: "\f095";
            font-family: 'FontAwesome';
            font-size: 18px;
            margin-right: 8px;
          }
        }
      }
    }
  }
}


///// Main Menu
#logo--main {
  padding: 10px 0;
  img {
    width: 100%;
    height: auto;
    position: relative;
    top: 3px;
  }
}

.dropdown-toggle {
  &::after {
    margin-top: -4px;
  }
}

.dropdown-menu {
  padding: 10px 0;
  padding-right: 20px;
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid #44a446;
  li {
    padding: 10px 0 5px 0;
    transition: all 0.2s;
    &:hover {
      padding-left: 10px;
      transition: all 0.2s;
    }
    a {
      &.active {
        border: 0;
      }
    }
  }
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}


@media (max-width: 992px) {
  .dropdown-menu {
    li {
      padding: 0;
      margin: 5px 0 5px 20px !important;
    }
  }
}

.navbar-toggleable-md .navbar-collapse {
  width: auto !important;
  justify-content: flex-end;
}

.nav-link {
  padding-bottom: .2em;
  text-transform: uppercase;
}

#block--main-menu {
  margin-top: 10px;
  ul {
    li {
      margin: 0;
      margin-bottom: 10px;
      a {
        font-size: 17px;
        padding: 0;
      }
      span {
        font-size: 17px;
        padding-top: 0;
      }
      &:last-child {
        margin-right: 0 !important;
        a {
          padding-right: 0 !important;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .navbar-toggleable-md .navbar-collapse {
    width: 100% !important;

    .menu__list {
      margin-top: 1.2rem;
      li {
        a {
          padding: 0px 1rem;
        }
        margin-bottom: 0.5rem;
      }
    }
  }
}

.dropdown-item {
  transition: all ease-in-out 300ms;
}

.dropdown-item.active {
  @extend .c-font-weight--bold;
  background: transparent;
  border-bottom: 2px solid $brand-primary;
  color: $gray-dark;
}

.dropdown-item:hover, .dropdown-item:focus {
  background: transparent;
  color: $gray-light;
}

.navbar-toggler {
  border: 1px solid $brand-primary;
}
